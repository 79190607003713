import React from 'react';
import './Home.css';
import app from '../../assets/images/phone1.png';
import { DownloadButton, HomeTextInfo } from '../../components';

const Home = () => {
	return (
		<section className='home-container flex'>
			<HomeTextInfo />
			<div className='home-img-container'>
				<img src={app} alt='ro-bear app' height={1000} />
			</div>
			<div className='mobile-btn'>
				<DownloadButton />
			</div>
		</section>
	);
};

export default Home;
