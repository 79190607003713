import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeaderStyledNav } from './HeaderNav.styled';

const HeaderNav = () => (
	<HeaderStyledNav>
		<ul>
			<li>
				<NavLink to='/' exact activeClassName='active-route'>
					Acasă
				</NavLink>
			</li>

			
			<li>
				<a href='#download'>
					<div>Descarcă</div>
				</a>
			</li>
		</ul>
	</HeaderStyledNav>
);

export default HeaderNav;
