import React from "react";
import "./Footer.css";
import whiteLogo from "../../assets/images/robear.png";
import pinLogo from "../../assets/images/bear-white.png";

const Footer = () => (
  <footer className='footer-container'>
    <nav className='footer-nav flex-1'>
      <ul>
        <li>
          <span style={{fontSize: '28px'}}>Contact</span>
        </li>
        <li>
          <a href="mailto=support@ro-bear.ro">support@ro-bear.ro </a>
        </li>
        <li>
          <a href="mailto=contact@ro-bear.ro">contact@ro-bear.ro </a>
        </li>
      </ul>
    </nav>
    <div className='footer-logo flex-1'>
      <img src={pinLogo} alt='RO-BEAR' height={60} width={'auto'}/>
      <img src={whiteLogo} alt='RO-BEAR' width={'auto'} height={30} />
    </div>
    <span className='flex-1 span'></span>
  </footer>
);

export default Footer;
