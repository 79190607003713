import React from 'react';
import googleBadge from '../../assets/images/a-btn.png';
import appleBadge from '../../assets/images/i-btn.png';
import qra from '../../assets/images/qr-a.png';
import qri from '../../assets/images/qr-i.png';
import { StyledHomeTextInfo } from './HomeTextInfo.styled';

const HomeTextInfo = () => (
	<StyledHomeTextInfo className='home-info-container flex'>
		<div>
			<div className='home-name'>
				RO-BEAR
			</div>
			<div className='home-title'>
				Urmărește și raportează
			</div>
			<div className='home-title'>întâlnirile cu urșii</div>
			<br /><br /><br />
			<div className='home-secondary-title'>
				Verifică daca a fost văzut recent
			</div>
			<div className='home-secondary-title'>
				un urs în zona în care urmează să mergi.
			</div>
			<div className='home-meet'>Fii informat!</div>
		</div>
		<section className='home-badges flex' id='download'>
			<div className='home-badge-a'>
				<a href='https://play.google.com/store/apps/details?id=com.codenitc.ROBEAR' target='_blank' rel="noopener noreferrer">
			<img src={googleBadge} alt='google download' height={'auto'} width={250}  />
			</a>
			<img src={qra} alt='google download' height={250} width={'auto'}  />
			</div>
			<div className='home-badge-i'>
				<a href='https://apps.apple.com/ro/app/ro-bear/id6652549528' target='_blank' rel="noopener noreferrer">
			<img src={appleBadge} alt='apple download' height={'auto'} width={250} />
			</a>
			<img src={qri} alt='apple download' height={250} width={'auto'} />
			</div>
		</section>
	</StyledHomeTextInfo>
);

export default HomeTextInfo;
